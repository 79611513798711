//import logo from './logo.svg';
import './App.css';
import Amplify, { API } from 'aws-amplify'
import React, { Component} from 'react';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import logo from './images/Aug24QR.png';
import augpdf from './qrcodeaug24.pdf';
import seppdf from './qrcodesep24.pdf';
import octpdf from './qrcodeoct24.pdf';
import novpdf from './qrcodenov24.pdf';

function App() {

    return (
      <div className = "App">
        
      <Authenticator>
      {({ signOut, user }) => (
        <div className="App">
          <p>
            Hello {user.username}, Welcome to BCSW Amarra
          </p>
          <button onClick={signOut}>Sign out</button>
          <ul></ul>
        </div>
      )}
      </Authenticator>

          <img src={logo} alt="Logo" width="250" height="250"/>

          <p>
           Aug 2024 Gate Code: <b>60816</b>
          <br></br>
          Pass and Code expire 09/10/2024
          </p>
            <a
            className="App-link"
            href="https://pass.myq.com/KXsw1RbNHkKLKrmLNOJMEQCI6iOyseDEifvCZxnmEzDw"
            target = "blank"
            rel = "noopener noreferrer"
            >
              Follow this link to open gate by tapping your screen
            </a>
            <br></br>
            <br></br>

            <a href = {augpdf} target = "_blank">Print August 2024 QR Code Sheet</a>
            <br></br>
            <a href = {seppdf} target = "_blank">Print September 2024 QR Code Sheet</a>
            <br></br>
            <a href = {octpdf} target = "_blank">Print October 2024 QR Code Sheet</a>
            <br></br>
            <a href = {novpdf} target = "_blank">Print November 2024 QR Code Sheet</a>
      </div>
      
    );
    
}
export default withAuthenticator(App);
